import * as React from "react";
import Hero from "../components/hero";
import Tail from "../components/tail";
import TextBlock from "../components/text";
import Seo from "../components/seo";
import Header2 from "../components/header/header2";

import MGCaption from "../components/MGCaption";
import NaviNext from "../components/NaviNext";

import { StaticImage } from "gatsby-plugin-image";
import ImageSlider from "../components/ImageSlider";
import PieCenter4 from "../components/histograms/PieCenter4";
import PieCenter5 from "../components/histograms/PieCenter5";
import DynPlot2 from "../components/histograms/dynplot2";
import DynPlot3 from "../components/histograms/dynplot3";
import Histo12 from "../components/histograms/histo12";
import Short from "../components/short";
import SinglePhoto from "../components/SinglePhoto";

import { fInt, fFloat } from "../components/helper";

const Part7 = () => {
  return (
    <Tail>
      <Hero>
        <h1>
          РАЗДЕЛ 7. <br />
          КАДРОВАЯ И СОЦИАЛЬНАЯ ПОЛИТИКА
        </h1>
      </Hero>

      <TextBlock>
        <div>
          <Short
            text="Кадровая и социальная политика"
            link="https://www.mos-gaz.ru/about/job/social/"
            short_id="short_7-1"
            className="w-[300px]"
          />

          <p className="mb-1">
            Наиболее приоритетными направлениями кадровой и социальной политики
            в 2021 году АО «МОСГАЗ» являлись:
          </p>

          <ul className="list-disc list-outside">
            <li>
              <p>
                обеспечение защиты персонала в условиях распространения новой
                коронавирусной инфекции (2019-nCoV);
              </p>
            </li>
            <li>
              <p>
                развитие эффективной кадровой политики в сфере поиска,
                привлечения и подбора персонала (формирование кадрового
                резерва);
              </p>
            </li>
            <li>
              <p>
                повышение уровня профессиональных компетенций сотрудников, с
                целью их дальнейшего карьерного продвижения в Обществе;
              </p>
            </li>
            <li>
              <p>
                обеспечение социальной защищенности работников путем
                удовлетворения социальных и материальных потребностей работников
                Общества.
              </p>
            </li>
          </ul>
        </div>

        <p>
          В соответствии с намеченной стратегией в 2021 году было проведено
          более 1200 тестирований по выявлению профессиональных навыков и
          личностных компетенций, а также более 4500 индивидуальных
          собеседований, что позволило повысить качество и скорость подбора
          персонала.
        </p>
        <div className="md:max-w-[450px] lg:max-w-[550px] mb-5 ml-5">
          <StaticImage
            src="../images/part-07/7.2.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
          />
        </div>
        <p>
          Автоматизирован процесс адаптации сотрудников Общества, что позволяет
          оперативно отслеживать и координировать ввод в должность новых
          сотрудников, повышая их вовлеченность в работу и производительность.
        </p>

        <Header2>7.1. СТРУКТУРА КАДРОВОГО СОСТАВА</Header2>

        <p>
          По состоянию на 31.12.2021 численность персонала АО «МОСГАЗ» составила
          4299 человек.
        </p>

        <MGCaption>
          Структура кадрового состава по категориям работников
        </MGCaption>
        <PieCenter4 idkey={"pie4"}></PieCenter4>

        <p>
          Количество работающих мужчин на 31.12.2021 года составило 2998 (70% от
          общей численности), женщин – 1301 (30% от общей численности). Средний
          возраст персонала в 2021 году составил 43 года.
        </p>

        <MGCaption>Возрастной состав персонала</MGCaption>
        <PieCenter5 idkey={"pie5"}></PieCenter5>

        <Header2>7.2. ОПЛАТА ТРУДА И СИСТЕМА МОТИВАЦИИ ПЕРСОНАЛА</Header2>

        <div className="flex flex-col md:flex-row gap-5">
          <div className="flex flex-col">
            <MGCaption>Фонд заработной платы, млн руб.</MGCaption>
            <DynPlot2 idkey={"dyn2"}></DynPlot2>
          </div>
          <div className="md:mt-20 lg:mt-25">
            <p>
              Действующая система оплаты труда состоит из должностных окладов,
              премий, учитывающих квалификацию и деловые качества работников, а
              также надбавок и доплат, предусмотренных ТК РФ.
            </p>
            <p>
              Увеличение общего годового размера фонда оплаты труда составило
              605 тыс. руб. (1,1%).
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-5">
          <div className="flex flex-col">
            <MGCaption>Среднесписочная численность</MGCaption>
            <DynPlot3 idkey={"dyn3"}></DynPlot3>
          </div>
          <div className="md:mt-20 lg:mt-25">
            <p>Среднесписочная численность ниже прошлогодней на – 2,6%.</p>
            <p>
              Среднемесячная заработная плата в 2021 году увеличилась на 15,2% и
              составила 105 060 рублей.
            </p>
          </div>
        </div>

        <MGCaption>
          Динамика средней заработной платы по категориям работников, руб./мес.
        </MGCaption>

        <table className="tBig">
          <thead>
            <tr>
              <td>Наименование показателя</td>
              <td>2019</td>
              <td>2020</td>
              <td>2021</td>
              <td>2021/2020</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Средняя заработная плата – всего*, в том числе:</td>
              <td>{fInt(84_779)}</td>
              <td>{fInt(91_195)}</td>
              <td>{fInt(105_060)}</td>
              <td>{fFloat(15.2, 1)}%</td>
            </tr>
            <tr>
              <td>
                — средняя заработная плата руководителей, <br />
                специалистов и служащих
              </td>
              <td>{fInt(100_378)}</td>
              <td>{fInt(109_991)}</td>
              <td>{fInt(125_720)}</td>
              <td>{fFloat(14.3, 1)}%</td>
            </tr>
            <tr>
              <td>— средняя заработная плата рабочих</td>
              <td>{fInt(73_163)}</td>
              <td>{fInt(76_761)}</td>
              <td>{fInt(88_316)}</td>
              <td>{fFloat(15.1, 1)}%</td>
            </tr>
          </tbody>
        </table>
        <p className="text-sm italic">
          <sup>*</sup> С учетом внештатных сотрудников, работающих по договорам
          ГПХ.
        </p>

        <p className="mb-1">
          Рост средней заработной платы связан с рядом причин:
        </p>

        <ul className="list-disc list-outside">
          <li>
            <p>
              выплата в декабре 2021 года премии по результатам работы за год в
              размере 1,5 оклада;
            </p>
          </li>
          <li>
            <p>
              повышение заработной платы в среднем по Обществу на 10% с октября
              2021 года. Средний процент повышения рассчитан как суммарный
              процент роста индекса потребительских цен за двухлетний период –
              2021 и прогноз на 2022.
            </p>
          </li>
        </ul>

        <p>
          Пересмотр штатного расписания с повышением окладов произведен в целях
          повышения материальной заинтересованности работников и обеспечения
          повышения уровня реального содержания заработной платы, в том числе с
          учетом тенденций в изменениях уровней заработной платы работников
          соответствующих категорий и специальностей на рынке труда.
        </p>

        <MGCaption>
          Уровень заработной платы по основным рабочим специальностям
        </MGCaption>
        <table className="tBig">
          <thead>
            <tr>
              <td>Профессия/должность</td>
              <td>Разряд</td>
              <td>
                Оклад до <br />
                01.10.2021
              </td>
              <td>
                Оклад с <br />
                01.10.2021
              </td>
              <td>% роста</td>
              <td>
                Доход без учета <br />
                НДФЛ
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={2}>
                Слесарь по эксплуатации и ремонту <br />
                подземных газопроводов
              </td>
              <td>4</td>
              <td>{fInt(37_000)}</td>
              <td>{fInt(45_000)}</td>
              <td>22</td>
              <td>{fInt(62_640)}</td>
            </tr>
            <tr className="not-first-fix">
              <td>5</td>
              <td>{fInt(39_000)}</td>
              <td>{fInt(48_000)}</td>
              <td>23</td>
              <td>{fInt(66_816)}</td>
            </tr>

            <tr>
              <td rowSpan={2}>Электрогазосварщик</td>
              <td>5</td>
              <td>{fInt(40_000)}</td>
              <td>{fInt(55_000)}</td>
              <td>37</td>
              <td>{fInt(82_302)}</td>
            </tr>
            <tr className="not-first-fix">
              <td>6</td>
              <td>{fInt(46_000)}</td>
              <td>{fInt(63_000)}</td>
              <td>37</td>
              <td>{fInt(94_273)}</td>
            </tr>

            <tr>
              <td rowSpan={2}>Монтажник наружных трубопроводов</td>
              <td>5</td>
              <td>{fInt(40_000)}</td>
              <td>{fInt(48_000)}</td>
              <td>20</td>
              <td>{fInt(66_816)}</td>
            </tr>
            <tr className="not-first-fix">
              <td>6</td>
              <td>{fInt(46_000)}</td>
              <td>{fInt(53_000)}</td>
              <td>15</td>
              <td>{fInt(73_776)}</td>
            </tr>

            <tr>
              <td>Старший мастер</td>
              <td></td>
              <td>{fInt(52_000)}</td>
              <td>{fInt(60_000)}</td>
              <td>15</td>
              <td>{fInt(78_300)}</td>
            </tr>
          </tbody>
        </table>

        <p>
          Данные мероприятия позволили довести уровень заработной платы по
          основным рабочим специальностям до конкурентоспособного уровня: в
          течение года Обществом были понесены дополнительные расходы фонда
          оплаты труда для реализации незапланированных мероприятий на общую
          сумму 82,85 млн руб., которые также повлияли на уровень средней
          заработной платы.
        </p>

        <MGCaption>
          Дополнительные расходы фонда оплаты труда для реализации
          незапланированных мероприятий 2021 г.
        </MGCaption>

        <table className="tBig">
          <thead>
            <tr>
              <td>Мероприятие</td>
              <td>
                Фонд оплаты труда, <br />
                тыс. руб.
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Осуществление внепланового обследования состояния
                работоспособности газового хозяйства в жилых домах,
                оборудованных плитами, в соответствии с Поручением Правительства
                Москвы
              </td>
              <td>{fInt(22_924)}</td>
            </tr>
            <tr>
              <td>
                Работы по вывозу несанкционированных навалов грунта и
                строительного мусора с территории МГУ им. М.В. Ломоносова
              </td>
              <td>{fInt(32_427)}</td>
            </tr>
            <tr>
              <td>
                Работы по обеспечению безопасного функционирования городской
                системы в период обильных снегопадов (уборка снега)
              </td>
              <td>{fInt(25_233)}</td>
            </tr>
            <tr>
              <td>
                Работы по строительству 2 котельных и прокладке тепловой сети в
                целях подключения завода для производства вакцин на территории
                «Технополис «Москва» по адресу: Волгоградский проспект, д. 42
                корп. 5
              </td>
              <td>{fInt(497)}</td>
            </tr>
            <tr>
              <td>
                Выплата материального поощрения за успешное решение
                организационных вопросов по проведению важных городских
                мероприятий за счет гранта, предоставленного ДЖКХ г. Москвы по
                договору от 06.12.2021 No 222-ДЖКХ/21
              </td>
              <td>{fInt(1768)}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>ИТОГО дополнительные расходы</td>
              <td>{fInt(82_848)}</td>
            </tr>
          </tfoot>
        </table>

        <p>
          Вышеуказанные дополнительные расходы не включают в себя затраты на
          оплату основного рабочего времени сотрудников, а состоят из доплат за
          сверхурочное время, работы в выходные и праздничные дни, ночные часы и
          дополнительное премирование сотрудников за выполнение работ в сжатые
          сроки на внеплановых объектах.
        </p>

        <p>
          В 2021 году за высокие производственные показатели были награждены 144
          работника АО «МОСГАЗ», в том числе получены: 5 благодарностей
          Министерства энергетики Российской Федерации, 2 почетные грамоты
          Правительства Москвы, 5 благодарностей Мэра Москвы, 1 почетное звание
          «Почетный работник жилищно- коммунального хозяйства города Москвы».
        </p>

        <Header2>7.3. СИСТЕМА ОБУЧЕНИЯ И РАЗВИТИЯ ПЕРСОНАЛА</Header2>

        <p>
          АО «МОСГАЗ» уделяет особое внимание развитию профессиональных
          компетенций всех категорий работников и выполнению обязательных
          государственных требований к уровню подготовки персонала
          топливно-энергетического комплекса.
        </p>
      </TextBlock>
      <ImageSlider>
        <div className="m-2 md:m-5 max-w-[300px] lg:max-w-[500px]">
          <StaticImage
            src="../images/part-07/120A0157.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
          />
        </div>

        <div className="m-2 md:m-5 max-w-[300px] lg:max-w-[500px]">
          <StaticImage
            src="../images/part-07/00MAX_5958.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
          />
        </div>
        <div className="m-2 md:m-5 max-w-[300px] lg:max-w-[500px]">
          <StaticImage
            src="../images/part-07/120A0152.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
          />
        </div>
      </ImageSlider>

      <TextBlock>
        <article>
          <p>
            В целях реализации положений национальной политики по внедрению
            профессиональных стандартов, продолжено повышение квалификационного
            уровня сотрудников по программам дополнительного образования. Так, в
            2021 году 416 работников Общества прошли профессиональное обучение
            по программам профессиональной подготовки по следующим направлениям:
            «Слесарь по эксплуатации и ремонту внутридомового газового
            оборудования», «Слесарь по эксплуатации и ремонту подземных
            газопроводов», «Монтажник наружных трубопроводов», «Стропальщик»,
            «Сварщик полимерных материалов».
          </p>
          <Short
            text="Обучение и развитие персонала"
            link="https://www.mos-gaz.ru/about/job/specialists/"
            short_id="short_7-3"
            className="w-[250px]"
          />
          <p>
            В 2021 году проведено обязательное профессиональное обучение для
            рабочих специальностей в количестве 5351 человеко-дисциплин и
            дополни- тельное профессиональное образование специалистов и
            инженерно-техни- ческих работников в количестве 6235
            человеко-дисциплин. Более 70% обуче- ния было пройдено сотрудниками
            на базе корпоративного учебного центра АНО ДПО «УЦ МОСГАЗ».
          </p>

          <p>
            Расходы на аттестацию, профессиональное обучение и профессиональную
            подготовку в отчетном году составили 31 289 тыс. рублей.
          </p>

          <p>
            В рамках развития сотрудничества между АО«МОСГАЗ» и ведущими высшими
            учебными заведениями, колледжами Российской Федерации в 2021 году в
            Обществе прошли производственную и преддипломную практики 154
            студента; 6 выпускников общеобразовательных школ проходят целевое
            обучение в Национальном исследовательском Московском государственном
            строительном университете.
          </p>
        </article>
        <Header2>7.4. СОЦИАЛЬНАЯ ПОЛИТИКА</Header2>

        <p className="mb-1">
          Важнейшими направлениями социальной политики АО «МОСГАЗ» являются:
        </p>

        <ul className="list-disc list-outside">
          <li>
            <p>обеспечение безопасных условий труда;</p>
          </li>
          <li>
            <p>поддержка здорового образа жизни сотрудников;</p>
          </li>
          <li>
            <p>
              материальная поддержка многодетных семей и ветеранов газового
              хозяйства.
            </p>
          </li>
        </ul>

        <div className="mt-0">
          <div className="md:max-w-[350px] lg:max-w-[450px] mb-5 ml-5 md:float-right">
            <StaticImage
              src="../images/part-07/7.4.jpg"
              loading="eager"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt=""
            />
          </div>
          <p>
            Деятельность АО «МОСГАЗ» в 2021 году осуществлялась в соответствии с
            Трудовым кодексом Российской Федерации, Московским трехсторонним
            соглашением на 2021–2023 гг. и Коллективным договором, заключенным
            между Обществом и Региональной общественной организацией – Первичной
            профсоюзной организацией работников акционерного общества «МОСГАЗ»
            общественной организации «Профсоюз муниципальных работников Москвы».
          </p>
          <p className="mb-1">
            В рамках политики предприятия, направленной на поддержание здорового
            образа жизни и защиты персонала от распространения инфекционных
            заболеваний, в 2021 году были проведены мероприятия:
          </p>

          <ul className="list-disc list-outside">
            <li>
              <p>
                вакцинация работников от гриппа – вакцинировано 82% работников
                Общества;
              </p>
            </li>
            <li>
              <p>
                вакцинация работников от коронавирусной инфекции (2019-nCoV) –
                вакцинировано 93% работников Общества.
              </p>
            </li>
          </ul>
        </div>

        <MGCaption>
          Расходы на финансирование социальных выплат по Коллективному договору,
          тыс. руб.
        </MGCaption>

        <table className="tBig">
          <thead>
            <tr>
              <td>Вид выплаты</td>
              <td>2019</td>
              <td>2020</td>
              <td>2021</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Единовременное премирование из прибыли</td>
              <td>{fInt(43_833)}</td>
              <td>{fInt(45_049)}</td>
              <td>{fInt(39_471)}</td>
            </tr>
            <tr>
              <td>
                Материальная помощь всех видов (к отпуску, многодетным семьям,
                при увольнении, при выходе на пенсию, в связи со значительными
                событиями)
              </td>
              <td>{fInt(46_032)}</td>
              <td>{fInt(49_427)}</td>
              <td>{fInt(54_645)}</td>
            </tr>
            <tr>
              <td>Оплата дополнительного отпуска</td>
              <td>{fInt(35_540)}</td>
              <td>{fInt(29_418)}</td>
              <td>{fInt(43_915)}</td>
            </tr>
            <tr>
              <td>Денежное поощрение в связи с юбилейной датой</td>
              <td>{fInt(11_899)}</td>
              <td>{fInt(14_632)}</td>
              <td>{fInt(14_403)}</td>
            </tr>
            <tr>
              <td>
                Компенсация стоимости путевок в дома отдыха, санатории, детские
                оздоровительные лагеря
              </td>
              <td>{fInt(10_980)}</td>
              <td>{fInt(1041)}</td>
              <td>{fInt(1634)}</td>
            </tr>
            <tr>
              <td>Надбавка ветеранам газового хозяйства</td>
              <td>{fInt(5246)}</td>
              <td>{fInt(4848)}</td>
              <td>{fInt(4274)}</td>
            </tr>
            <tr>
              <td>Прочие выплаты</td>
              <td>{fInt(539)}</td>
              <td>{fInt(755)}</td>
              <td>{fInt(601)}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>ИТОГО:</td>
              <td>{fInt(154_069)}</td>
              <td>{fInt(145_170)}</td>
              <td>{fInt(158_943)}</td>
            </tr>
          </tfoot>
        </table>

        <Header2>7.5. ОХРАНА ТРУДА</Header2>

        <p>
          Важнейшими задачами АО «МОСГАЗ» в области охраны труда является
          обеспечение безаварийных производственных процессов, безопасных
          условий труда и сохранность здоровья работников.
        </p>

        <p className="mb-1">
          В целях реализации указанных задач Общество руководствуется следующими
          принципами:
        </p>
        <ul className="list-disc list-outside">
          <li>
            <p>снижение и минимизация рисков на рабочих местах;</p>
          </li>
          <li>
            <p>
              приоритетность жизни и здоровья людей по отношению к результату
              производственной деятельности;
            </p>
          </li>
          <li>
            <p>
              ответственность каждого работника за свою собственную безопасность
              и безопасность окружающих их людей;
            </p>
          </li>
          <li>
            <p>
              вовлечение всех работников Общества в планомерный процесс по
              снижению производственного травматизма, рисков возникновения
              аварийных ситуаций, а также заболеваний людей.
            </p>
          </li>
        </ul>

        <SinglePhoto>
          <StaticImage
            src="../images/part-07/7.5.1-l.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
          />
        </SinglePhoto>

        <p className="mb-1">
          Общество, стремясь соответствовать наилучшим мировым отраслевым
          показателям в области охраны труда ставит перед собой следующие
          стратегические цели:
        </p>
        <ul className="list-disc list-outside">
          <li>
            <p>
              стремиться к цели «Ноль», предотвращать несчастные случаи на
              производстве;
            </p>
          </li>
          <li>
            <p>
              обеспечивать безопасность производственных процессов за счет
              повышения надежности, безопасности работы технологического
              оборудования;
            </p>
          </li>
          <li>
            <p>
              постоянно повышать уровень знаний персонала Общества в вопросах
              охраны труда.
            </p>
          </li>
        </ul>

        <p>
          В компании действует положение «О системе управления охраной труда АО
          «МОСГАЗ». Положением установлены требования по реализации политики в
          области охраны труда, целей в области охраны труда.
        </p>

        <p className="mb-1">
          Основными направлениями политики в области охраны труда являются:
        </p>
        <ul className="list-disc list-outside">
          <li>
            <p>
              обеспечение приоритета сохранения жизни и здоровья работников
              Общества;
            </p>
          </li>
          <li>
            <p>создание безопасных условий труда на рабочих местах;</p>
          </li>
          <li>
            <p>
              профилактика несчастных случаев и повреждения здоровья работников;
            </p>
          </li>
          <li>
            <p>
              обязательное привлечение работников, уполномоченных ими
              представительных органов к участию в управлении охраной труда и
              обеспечении условий труда, соответствующих требованиям охраны
              труда.
            </p>
          </li>
        </ul>

        <p>
          АО «МОСГАЗ» ведет постоянную работу по повышению культуры безопасности
          труда, формированию сознательного отношения работников к вопросам
          безопасности, идентификации источников опасности и корректировке
          опасных действий и условий, проводятся «дни охраны труда».
        </p>

        <SinglePhoto>
          <StaticImage
            src="../images/part-07/7.5.3.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
          />
        </SinglePhoto>

        <p>
          В ежедневном режиме проводятся оперативные проверки выполнения работ
          на объектах строительства. В 2021 году проведено 512 оперативных
          проверок состояния требований охраны труда. Плановые проверки
          состояния охраны труда в структурных подразделениях проводятся по
          утвержденному графику. По каждому выданному предписанию разработаны
          мероприятия по устранению выявленных нарушений и исключению повторных
          аналогичных замечаний.
        </p>

        <p>
          В течение года в структурных подразделениях Общества проведены
          обучения и очередные проверки знаний правил, инструкций,
          законодательных и нормативно- технических документов по охране труда,
          промышленной безопасности, всего проверку знаний прошли 2441 чел.
          Также работники прошли обучение навыкам оказания первой помощи
          пострадавшим при несчастных случаях на производстве с применением
          робота-тренажера.
        </p>

        <p>
          Сумма затрат на финансирование мероприятий по улучшению условий и
          охраны труда, предупреждению профессиональных заболеваний (в целом по
          Обществу) в 2021 году составила 107 133 200 руб., в том числе на
          одного работника – 24 380 руб.
        </p>

        <MGCaption>Затраты на мероприятия по охране труда</MGCaption>

        <Histo12 idkey={"histo12"}></Histo12>

        <p>
          Управление в сфере охраны труда определяется требованиями
          законодательства РФ и действующих нормативных правовых актов.
        </p>

        <p>
          В целях профилактики и выявления профессиональных заболеваний в
          соответствии с требованиями статьи 213 Трудового кодекса Российской
          Федерации, Приказа Министерства здравоохранения Российской федерации
          от 28.01.2021 № 29н «Об утверждении Порядка проведения обязательных
          предварительных и периодических медицинских осмотров работников,
          предусмотренных частью четвертой статьи 213 Трудового кодекса
          Российской Федерации, перечня медицинских противопоказаний к
          осуществлению работ с вредными и (или) опасными производственными
          факторами, а также работам, при выполнении которых проводятся
          обязательные предварительные и периодические медицинские осмотры», а
          также снижения рисков травматизма на производстве в АО «МОСГАЗ»
          организованы периодические медицинские осмотры и психиатрические
          освидетельствования.
        </p>

        <p>
          В 2021 году выдано 2571 направление для прохождения периодического
          медицинского осмотра, 614 работников прошли регулярные предрейсовые,
          послерейсовые медицинские осмотры.
        </p>

        <table className="tBig">
          <thead>
            <tr>
              <td>Год</td>
              <td>Кол-во предрейсовых и послерейсовых осмотров</td>
              <td>Периодический медосмотр, чел.</td>
              <td>Психиатрическое освидетельствование, чел.</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2019</td>
              <td>{fInt(131_543)}</td>
              <td>{fInt(2369)}</td>
              <td>{fInt(0)}</td>
            </tr>
            <tr>
              <td>2020</td>
              <td>{fInt(131_995)}</td>
              <td>{fInt(2376)}</td>
              <td>{fInt(0)}</td>
            </tr>
            <tr>
              <td>2021</td>
              <td>{fInt(132_261)}</td>
              <td>{fInt(2571)}</td>
              <td>{fInt(1552)}</td>
            </tr>
          </tbody>
        </table>

        <p>
          Работники Общества в полном объеме обеспечены средствами
          индивидуальной защиты и моющими средствами. Все выдаваемые СИЗ
          сертифицированы и отвечают современным требованиям технических
          регламентов и ГОСТов.
        </p>

        <p>
          Организован централизованная химчистка, стирка, ремонт специальной
          одежды и дезинфекция специальной обуви, услугой воспользовались 2536
          работников.
        </p>

        <p>
          Для выявления и оценки уровня влияния опасных и вредных факторов
          производственной среды, оказывающих вредное воздействие на работников,
          определения интенсивности и степени этого воздействия, обоснования
          компенсаций и льгот работникам, занятым на работах с вредными
          условиями труда, проводится специальная оценка условий труда (СОУТ).
        </p>

        <p>
          В 2021 году проведена специальная оценка условий труда на 2215 рабочих
          местах (учитывая 100%-ную потребность) по результатам: 2130 рабочих
          мест задекларированы с безопасными или допустимыми условиями труда, 85
          рабочих мест с вредными условиями труда с классом 3.1 и 3.2.
        </p>

        <p>
          Работники, занятые на работах с вредными условиями труда по
          результатам СОУТ, обеспечены молоком, им предоставляется
          дополнительный отпуск, осуществляются соответствующие доплаты.
        </p>

        <p>
          Для оказания медицинской помощи организована работа медицинского
          пункта, бригады укомплектованы аптечками первой помощи. Весь персонал
          Общества застрахован по программе добровольного страхования от
          несчастных случаев на производстве.
        </p>

        <p>
          В 2021 году плановых и внеплановых проверок Государственной инспекцией
          труда в отношении АО «МОСГАЗ» не проводилось.
        </p>

        <p>
          По итогам 2021 года программа мероприятий в области охраны труда
          реализована в полном объеме.
        </p>
        <NaviNext
          next="/part-8"
          title="РАЗДЕЛ 8. ПРОИЗВОДСТВЕННАЯ БЕЗОПАСНОСТЬ И ЭКОЛОГИЧЕСКАЯ ОТВЕТСТВЕННОСТЬ"
        />
      </TextBlock>
    </Tail>
  );
};

export default Part7;

export const Head = () => (
  <Seo title="РАЗДЕЛ 7. КАДРОВАЯ И СОЦИАЛЬНАЯ ПОЛИТИКА" />
);
