import * as React from "react";
import * as d3 from "d3";
import { fIntG } from "../helper";

const getNextColor = (colors) => {
  let i = 0;

  return () => {
    i++;
    if (i === colors.length) {
      i = 0;
    }
    return colors[i];
  };
};

const locale = d3.formatLocale({
  decimal: ",",
  thousands: "\u00a0",
  grouping: [3],
  currency: ["", ""],
  minus: "\u2212",
  percent: "\u202f%",
});

const Histo12 = ({ idkey }) => {
  const data = [
    { group: "all", 2019: 100_298.9, 2020: 97_974.0, 2021: 107_133.2 },
    { group: "one", 2019: 22.04, 2020: 22.38, 2021: 24.38 },
  ];

  const margin = { top: 70, right: 30, bottom: 50, left: 70 },
    width = 450 - margin.left - margin.right,
    height = 450 - margin.top - margin.bottom;

  React.useEffect(() => {
    const selector = `.HistoOld-${idkey}`;
    document.querySelector(selector).innerHTML = "";

    const svg = d3
      .select(selector)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const xAxis = {
      all: "Всего,\nтыс. руб",
      one: "На одного работника, \nтыс. руб.",
    };

    const subgroups = ["2019", "2020", "2021"];
    const groups = ["all", "one"];

    const x = d3.scaleBand().domain(groups).range([0, width]).paddingInner([0.4]);

    // svg
    //   .append("g")
    //   .attr("transform", `translate(0, ${height})`)
    //   .call(
    //     d3
    //       .axisBottom(x)
    //       .tickSize(0)
    //       .tickFormat((d) => {
    //         console.log(d);
    //         return xAxis[d];
    //       })
    //   );

    const wrap = (el) => {
      // console.log(el);
      // console.log(d3.axisBottom(x));
      return d3.axisBottom(x).tickSize(0)(el);
    };

    const insertLinebreaks = (d, i, a) => {
      const t = a[i];
      var el = d3.select(t);
      var words = xAxis[d3.select(t).text()].split("\n");

      el.text("");

      for (var i = 0; i < words.length; i++) {
        var tspan = el.append("tspan").text(words[i]);
        if (i > 0) tspan.attr("x", 0).attr("dy", "12");
      }
    };

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(wrap)
      .selectAll("text")
      .each(insertLinebreaks)
      .attr("transform", "translate(0, 12)");

    // Add Y axis
    const y = d3.scaleLinear().domain([0, 110_000]).range([height, 0]);
    svg.append("g").call(d3.axisLeft(y).tickFormat(fIntG));

    // Add Y2 axis
    const y2 = d3.scaleLinear().domain([0, 25]).range([height, 0]);
    svg
      .append("g")
      .call(d3.axisRight(y2))
      .attr("transform", "translate(" + width + ", 0)");

    // Another scale for subgroup position?
    const xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([0.05]);

    const color = d3
      .scaleOrdinal()
      .domain(subgroups)
      .range(["#aaa", "#ccc", "#FFA500"]);

    svg
      .append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(data)
      .join("g")
      .attr("transform", (d) => {
        return `translate(${x(d.group)}, 0)`;
      })
      .selectAll("rect")
      .data(function (d) {
        return subgroups.map(function (key) {
          return { key: key, value: d[key], group: d.group };
        });
      })
      .join("rect")
      .attr("x", (d) => xSubgroup(d.key))
      .attr("y", (d) => {
        return d.group === "all" ? y(d.value) : y2(d.value);
      })
      .attr("width", xSubgroup.bandwidth())
      .attr("height", (d) => {
        const yh = d.group === "all" ? y(d.value) : y2(d.value);
        return height - yh;
      })
      .attr("fill", (d) => color(d.key))
      .exit()
      .data(function (d) {
        return subgroups.map(function (key) {
          return { key: key, value: d[key], group: d.group };
        });
      })
      .join("text")
      .attr("x", (d) => xSubgroup(d.key) - 12)
      .attr("y", (d) => {
        return (d.group === "all" ? y(d.value) : y2(d.value)) + 18;
      })
      .attr("width", xSubgroup.bandwidth())
      .attr("height", (d) => {
        const yh = d.group === "all" ? y(d.value) : y2(d.value);
        return height - yh;
      })
      .text((d) => locale.format("$,")(d.value))
      .style("font-size", "12px")
      .style("fill", (d) => {
        return "black";
      })
      .style("font-weight", "bold")
      .attr("text-anchor", (d) => {
        return "end";
      })
      .attr("transform", (d) => {
        const yh = d.group === "all" ? y(d.value) : y2(d.value);
        return `rotate(-90, ${xSubgroup(d.key)}, ${yh})`;
      });
  }, [idkey]);

  const ldata = [
    {
      label: "2019",
      color: "",
    },
    {
      label: "2020",
      color: "",
    },
    {
      label: "2021",
      color: "",
    },
  ];
  const lcolor = ["bg-[#aaa]", "bg-[#ccc]", "bg-[#FFA500]", "bg-[#cb953e]"];
  const getLegendColor = getNextColor(lcolor);

  return (
    <>
      <div className="flex flex-col md:flex-row">
        <div className={`HistoOld-${idkey}`}></div>
        <div className="max-w-4xl m-auto">
          <ul className="list-none pl-0 text-300 my-5 text-sm flex flex-row md:flex-col gap-2">
            {ldata.map((el, i) => (
              <li key={i}>
                <span className={`legend-pseudo ${lcolor[i]}`}></span>
                {el.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Histo12;
